<template>    
    <Dialog style="display: flex; overflow-x:auto;" modal :header="'Proveedores del Baremo ' + nombre" :style="{ width: '800px'}">
        <h5><strong>Agregar proveedor</strong></h5>
        <div style="display: flex; justify-content: space-between; margin-bottom: 25px;">
            <MultiSelect v-model="proveedorSeleccionado" :options="proveedoresNoAgregados" optionLabel="nombre_proveedor" optionValue="id" placeholder="Seleccione compañía para agregar"
            :maxSelectedLabels="3" style="width: 90%;"/>
            <button type="button" class="btn btn-primary btn-sm" @click="agregarProveedor()">Agregar</button>
        </div>

        <h5 style="margin-top: 20px;"><strong>Proveedores agregados</strong></h5>
        <div class="table-responsive">
            <table class="table table-striped">
                <tbody v-for="(proveedor, index) in proveedoresAgregados" :key="proveedor.id">                    
                    <tr v-if="index%2==0" style="background-color: rgba(0, 0, 0, .05);">
                        <td><h5>{{ proveedor.nombre_proveedor }}</h5></td>
                        <td @click="eliminarProveedor(proveedor)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                    </tr>
                    <tr v-else style="background-color: white;">
                        <td><h5>{{ proveedor.nombre_proveedor }}</h5></td>
                        <td @click="eliminarProveedor(proveedor)" style="text-align: end;"><button class="btn btn-danger btn-sm">Eliminar</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Dialog>
</template>


<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
export default {
    props: ["id", "nombre"],
    data() {
        return {
            datos:'',
            proveedores:[],
            proveedoresAgregados:[],
            proveedoresNoAgregados:[],
            proveedorSeleccionado:'',
        };
    },
    components: {
        Dialog,
        MultiSelect,
    },
    computed: {  
    },    
    methods: {        
        async eliminarProveedor(proveedor){
            const api = new PwgsApi();
            await api.delete('baremos-pwgs/'+this.$props.id+'/proveedor/'+proveedor.id);

            this.cargarDatos();
            this.obtenerProveedores();
            this.$emit('cambiolista');
        },
        async agregarProveedor(){
            if(this.proveedorSeleccionado==""){ 
                alert('Debe seleccionar un proveedor');
                return;
            }
            const api = new PwgsApi();
            await api.post('baremos-pwgs/'+this.$props.id+'/proveedores',{ids_proveedores:this.proveedorSeleccionado});
            
            this.proveedorSeleccionado = '';
            this.cargarDatos();
            this.obtenerProveedores();    
            this.$emit('cambiolista');     
        },
        async cargarDatos() {
            const api = new PwgsApi();            
            this.datos = await api.get('baremos-pwgs/'+this.id);
        },
        async obtenerProveedores() {
            const api = new PwgsApi();
            const response = await api.get('proveedores/?sortField=nombre_proveedor&sortOrder=1');
            this.proveedores = response.datos;
            console.log('dats', this.datos);
            var idBaremosAgregados = this.datos.id_proveedores;
            this.proveedoresAgregados = this.proveedores.filter(proveedor => 
                idBaremosAgregados.includes(proveedor.id)
            );
            this.proveedoresNoAgregados = this.proveedores.filter(proveedor => 
                !idBaremosAgregados.includes(proveedor.id)
            ); 
        },
    },
    mounted() {
        this.cargarDatos();
    },
    watch: {
        datos(){
            this.obtenerProveedores();
        }
    },
}
</script>
<style scoped>
</style>